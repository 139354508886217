import { Link } from "react-router-dom"
import { hostImg } from "../../../../classes"

export const Courses = (props: any) => {
    return(
        <div className="courses-item" style={{paddingBottom: "70px"}}>
            <div className="courses-image" style={{backgroundImage: `url('${hostImg}${props.item.image}')`}}></div>
            <div className="courses-desc">
                <h3>{props.item.title}</h3>
                <p>{props.item.miniDesc}</p>
            </div>
            <div className="courses-wrapper">
                <div className="courses-btns">
                    <div className="row">
                        <div className="col-12">
                            <Link to={`/account/${props.item.pk}/`}>
                                Продолжить обучение
                                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00005 6.36396H13.728M13.728 6.36396L8.40303 1.03901M13.728 6.36396L8.40303 11.6889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}