import { Route } from "react-router-dom"
import { MainPage } from "./pages/MainPage"
import { RegisterPage } from "./pages/RegisterPage"
import { UpdatePassPage } from "./pages/UpdatePassPage"
import { LoginPage } from "./pages/LoginPage"
import { CatalogPage } from "./pages/CatalogPage"
import { CoursePage } from "./pages/CoursePage"
import { OrderPage } from "./pages/OrderPage"
import { AccountPage } from "./pages/AccountPage"
import { PurchasedCoursePage } from "./pages/Purchased/PurchasedCoursePage"
import { PurchasedModulePage } from "./pages/Purchased/PurchasedModulePage"
import { PurchasedLessonPage } from "./pages/Purchased/PurchasedLessonPage"
import { FavouritesPage } from "./pages/FavouritesPage"
import { GlobalContext } from "./context/GlobalContext"
import { useContext } from "react"
import { NotPage } from "./pages/404"
import { CorporatePage } from "./pages/CorporatePage"
import { OfferPage } from "./pages/OfferPage"
import { PrivacyPage } from "./pages/PrivacyPage"
import { UserAgreementPage } from "./pages/UserAgreementPage"


export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext)
    
    return (
        <>
            <Route path={`/`} element={<MainPage/>} />
            <Route path={`/register/`} element={<RegisterPage/>} />
            <Route path={`/password/`} element={<UpdatePassPage/>} />
            <Route path={`/login/`} element={<LoginPage/>} />
            <Route path={`/courses/`} element={<CatalogPage/>} />
            <Route path={`/courses/:pk/`} element={<CoursePage/>} />
            <Route path={`/favourites/`} element={<FavouritesPage/>} />
            <Route path={`/order/`} element={<OrderPage/>} />
            
            <Route path={`/corporate/`} element={<CorporatePage/>} />

            <Route path={`/offer/`} element={<OfferPage/>} />
            <Route path={`/privacy-policy/`} element={<PrivacyPage/>} />
            <Route path={`/user-agreement/`} element={<UserAgreementPage/>} />
            

            {
                auth.user.userToken != "" ? (
                    <>
                        <Route path={`/account/`} element={<AccountPage/>} />
                        <Route path={`/account/:pk/`} element={<PurchasedCoursePage/>} />
                        <Route path={`/account/:course/:pk/`} element={<PurchasedModulePage/>} />
                        <Route path={`/account/:course/:module/:pk/`} element={<PurchasedLessonPage/>} />
                    </>
                ):""
            }
            
            <Route path="*" element={<NotPage />} />
            
        </>
    )
}