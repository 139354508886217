import { useEffect, useState } from 'react'
import logoLoader from '../../images/logoLoader.svg'
import { ReviewsList } from "../../classes"
import { ReviewsListItem } from '../ReviewsListItem'

export const ReviewsSlider = (props: any) => {
    const [load, setLoad] = useState(true)
    const [reviews, setReviews] = useState(new ReviewsList(2))
    
    useEffect(()=>{
        setLoad(true)
        reviews.getData(1, props.course ? props.course : 0, 2).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])


    if(load)
        return(
            <div 
                className="load-block"
                style={{width: "100%"}}
            >
                <img src={logoLoader} />
                <p>Загрузка</p>
            </div>
        )

        
    return(
        <div className="row">
            {
                reviews.list.length ? ( 
                    reviews.list.map((item, index) => {
                        return(
                            <ReviewsListItem item={item} col={6} />
                        )
                    })
                ):""
            }
        </div>
    )
}