import { useEffect } from "react"

export const NotPage = () => {
    useEffect(()=>{
        window.location.replace('/');
    },[])
    return (
        <section>
            <div 
                className="content"
                style={{
                    paddingTop: 100,
                    paddingBottom: 100
                }}
            >
                <h1
                    style={{
                        textAlign: "center",
                        fontSize: "100px"
                    }}
                >
                    404
                </h1>
            </div>
        </section>
    )
}