import { Link } from "react-router-dom"
import itemcourses from '../../images/item_courses.png'
import { hostImg } from "../../classes"

export const CoursesListItem = (props: any) => {
    return(
        <div className="courses-item">
            <div className="courses-image" style={{backgroundImage: `url('${hostImg}${props.item.image}')`}}></div>
            <div className="courses-desc">
                <h3>{props.item.title}</h3>
                <p>{props.item.miniDesc}</p>
            </div>
            <div className="courses-wrapper">
                <div className="courses-price">
                    <div className="row">
                        <div className="col-6">
                            <div className="courses-price__desc">
                                Стоимость курса
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="courses-price__cost">
                                {
                                    props.item.finalCost == 0 ? (
                                        <>Бесплатно</>
                                    ):(
                                        props.item.cost != props.item.finalCost ? (
                                            <>
                                                <span>{props.item.cost}₽</span>
                                                {props.item.finalCost}₽
                                            </>
                                        ):(
                                            <>
                                                {props.item.finalCost}₽
                                            </>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="courses-btns">
                    <div className="row">
                        <div className="col-6">
                            <div className="courses-btns__time">
                                Длительность<br/>
                                <span>{props.item.duration}</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <Link to={`/courses/${props.item.pk}/`}>
                                Подробнее
                                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00005 6.36396H13.728M13.728 6.36396L8.40303 1.03901M13.728 6.36396L8.40303 11.6889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}