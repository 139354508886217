export const OfferPage = () => {
    return(
        <section>
            <div 
                className="content"
                style={{
                    paddingTop: 50,
                    paddingBottom: 50
                }}
            >
                <h1 
                    className="title"
                    style={{
                        textAlign: "center"
                    }}
                >
                    ОФЕРТА
                </h1>
                <p style={{
                    textAlign: "center"
                }}>
                    на заключение договора о предоставлении доступа к материалам и оказании образовательных услуг
                </p>
                <p><b>1. Значение настоящей оферты </b></p>
                <p>
                    1.1. Настоящая оферта (далее – Оферта) является совместным предложением<br/>
                    Автономной некоммерческой организации Дополнительного профессионального образования «Внимание и забота», именуемой в дальнейшем «Исполнитель»<br/>
                    неограниченному кругу лиц (публичная оферта) заключить договор смешанной природы, включающий в себя условия лицензионного договора о предоставлении доступа к объектам исключительных прав и договора оказания образовательных услуг в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации на условиях, приведенных в настоящей Оферте (далее – Договор). 
                </p>
                <p>1.2.	Оферта считается направленной с момента ее размещения на сайте в сети Интернет по адресу https://kurs.als-info.ru/ и действует до момента ее отзыва или удаления со страниц указанного сайта. </p>
                <p>1.3.	Без ущерба положениям п. 1.2 недействительность одного или нескольких условий Оферты не влечет недействительности всех остальных условий Оферты. Оферта действует так, если бы условия, признанные или иным образом ставшие недействительными, не были включены в Оферту. </p>
                <p>1.4.	Полным и безоговорочным акцептом настоящей Оферты является подтверждение участником гражданского оборота, обладающим достаточной правоспособностью для заключения Договора (далее – «Заказчик») согласия с условиями Оферты и намерения заключить Договор (далее – Акцепт), выраженное в форме оплаты определенного информационно-образовательного продукта Исполнителя (далее – «Курс»). </p>
                <p>1.5.	Местом размещения Оферты считается город Москва, Российская Федерация. </p>
                <p>1.6.	Заказчик подтверждает, что Оферта и заключаемый путем Акцепта договор не содержат обременительных для него условий, которые он не принял бы при наличии у него возможности участвовать при определении условий Оферты и договора. </p>
                <p>1.7.	Акцептом настоящей Оферты в соответствии со статьей 438 ГК РФ Заказчик подтверждает, что он:</p>
                <p>
                    ● ознакомлен с условиями Оферты;<br/>
                    ● выражает согласие на получение образовательных услуг в рамках Курса на условиях Оферты.
                </p>
                <p><b>2. Термины и определения, указанные ниже трактуются в рамках настоящей Оферты и при исполнении Договора следующим образом:</b></p>
                <p><b>Курс</b>	программа дополнительного профессионального образования, соответствующая условиям настоящей Оферты, содержащая Материалы, а также образовательные услуги Исполнителя, реализуемая с использованием Сайта посредством предоставления Заказчику доступа к Материалам, а также оказания образовательных услуг, связанных с сопровождением изучения Материалов и прохождения Курса.</p>
                <p><b>Материалы</b> информационные материалы, буклеты, брошюры, листовки, рассылки, аналитическая информация, видеоматериалы и иные объекты исключительных прав Исполнителя или третьих лиц, право на которые предоставлено правообладателями Исполнителю, доступ к которым предоставляется Заказчику посредством Сайта. </p>
                <p><b>Сайт</b>	мультимедийный продукт, размещенный в информационно-коммуникационной сети Интернет по адресу https://kurs.als-info.ru/, исключительное право на который принадлежит Благотворительному фонду «Живи сейчас» и предоставлено Исполнителю в соответствии с законодательством Российской Федерации, состоящий из совокупности представленных в объективной форме данных и команд, имеющих целью получение определённого результата в виде организации процесса обучения Заказчика, посредством предоставления доступа к совокупности информации, текстов, графических элементов, дизайна, изображений, фото- и видеоматериалов и иных объектов интеллектуальной собственности, доступ к которым осуществляется путём предоставления Заказчику возможности использования различных данных и команд.</p>
                <p><b>Личный кабинет</b> Заказчика	совокупность не являющихся общедоступными элементов и функций Сайта страниц Платформы, созданных в результате регистрации Заказчика на Сайте. Доступ к Личному кабинету осуществляется посредством ввода учётных (аутентификационных) данных (логин и пароль) Заказчика после его регистрации на Сайте. </p>
                <p>При использовании Личного кабинета Заказчик понимает, что все действия, совершенные посредством его Личного кабинета, считаются совершенными им лично и подтверждены простой электронной подписью. Передача доступа к Личному кабинету третьим лицам запрещается. </p>
                <p>Закон Федеральный закон «Об образовании в Российской Федерации» от 29.12.2012 №273-ФЗ</p>
                
                <h3>3.	Предмет Договора</h3>
                <p>3.1.	Исполнитель обязуется предоставить Заказчику доступ к Материалам и с использованием Материалов оказать Заказчику образовательные услуги по программе дополнительного профессионального образования, информация об основных характеристиках которой доступна на Сайте, а Заказчик обязуется оплатить эти Услуги в соответствии с условиями раздела 5 Договора.</p>
                <p>3.2.	Продолжительность доступа к Материалам определяется в соответствии с информацией, размещенной на Сайте на момент Акцепта. </p>
                <p>3.3.	Услуги оказываются Исполнителем на основании лицензии на осуществление образовательной деятельности Л035-01298-77/00184633 от 17 декабря 2017 года.</p>

                <p><b>4. Права Исполнителя и Заказчика</b></p>
                <p>4.1.	Исполнитель вправе:</p>
                <p>4.1.1.	Самостоятельно осуществлять образовательный процесс, устанавливать системы оценок, формы, порядок и периодичность проведения аттестации Заказчика.</p>
                <p>4.1.2.	Применять к Заказчику меры поощрения и меры дисциплинарного взыскания в соответствии с законодательством Российской Федерации, Договором и локальными нормативными актами Исполнителя, которые могут быть размещены на сайте Исполнителя в сети Интернет по адресу https://allnurses.ru/.</p>
                <p>4.1.3.	Отчислить Заказчика по основаниям, предусмотренным законодательством РФ и локальными нормативными актами Исполнителя.</p>
                <p>4.1.4.	Потребовать оплаты разницы в стоимости обучения при переводе Заказчика с одной программы на другую.</p>
                <p>4.1.5.	Вносить изменения в учебный план, вызванные объективной необходимостью (например, в случае болезни преподавателя, технических проблем при функционировании Сайта).</p>
                <p>4.1.6.	Потребовать от Заказчика подтверждения данных, указанных им при акцепте Оферты, и запросить в связи с этим подтверждение личности Заказчика путем предоставления подтверждающих документов (в частности, документов, удостоверяющих личность).</p>
                <p>4.1.7.	Прекратить доступ Заказчика к Личному кабинету, Сайту и Материалам в случае, если Исполнителю станет известно о нарушении Заказчиком прав Исполнителя или правообладателей на Материалы.</p>

                <p><b>4.2.	Заказчик вправе:</b></p>
                <p>4.2.1.	Пользоваться правами, предусмотренными ч. 1 ст. 34 Закона.</p>
                <p>4.2.2.	Получать достоверную информацию от Исполнителя об оценке своих знаний, умений, навыков и компетенций через Личный кабинет. </p>
                <p>4.2.3.	Пользоваться в порядке, установленном локальными нормативными актами Исполнителя имуществом Исполнителя для целей освоения Курса. </p>
                <p>4.2.4.	Пользоваться Материалами Исполнителя исключительно для целей прохождения и освоения Курса, не осуществляя копирование, распространение, передачу третьим лицам, модификацию, доработку, иное изменение Материалов.</p>
                <p>4.2.5.	Подать заявление об отчислении по собственному желанию в любое время до окончания Курса.</p>

                <p><b>5.	Обязанности Исполнителя и Заказчика</b></p>
                <p>5.1.	Исполнитель обязан:</p>
                <p>5.1.1.	Зачислить Заказчика, выполнившего установленные законодательством Российской Федерации, локальными нормативными актами Исполнителя условия приема на обучение по Курсу.</p>
                <p>5.1.2.	Исполнитель обязан предоставить Заказчику доступ к Материалам Курса, размещенным на Сайте. Доступ к Материалам предоставляется не позднее одного рабочего дня, следующего за днем поступления денежных средств в счет стоимости Курса на расчетный счет Исполнителя, а в случае, если доступ к Материалам в соответствии с условиями, изложенными на Сайте, предоставляется безвозмездно – не позднее одного рабочего дня с даты регистрации Заказчиком Личного кабинета и предоставления всех данных, необходимых в соответствии с требованиями Сайта.</p>
                <p>5.1.3.	Исполнитель в течение срока, указанного в условиях доступа к Курсу, обязуется предоставлять услуги обратной связи, включающей консультирование Заказчика по Материалам Курса, разъяснения положений программы Курса в части, неизложенной в Материалах. Услуги, предусмотренные настоящим п. 4.1.3 оказываются в срок не позднее 10 (Десяти) рабочих дней с даты получения запроса Заказчика и строго в рабочее время Исполнителя с 10-00 часов до 17-00 часов по московскому времени. </p>
                <p>5.1.4.	Договор может быть расторгнут в части доступа к Материалам по инициативе Исполнителя в одностороннем внесудебном порядке по истечению 1 года с момента начала его действия, в случае отсутствия организационной, технической или юридической возможности предоставлять доступ к обучающим материалам на Сайте.</p>
                <p>5.1.5.	Организовать и обеспечить надлежащее оказание образовательных услуг в соответствии с государственными требованиями, локальными нормативными актами Исполнителей, учебным планом и расписанием занятий Исполнителя.</p>
                <p>5.1.6.	Выдать Заказчику документ об обучении (сертификат) в случае успешного освоения Курса.</p>
                <p>5.1.7.	Обеспечить Заказчику уважение человеческого достоинства, защиту от всех форм физического и психического насилия, оскорбления личности, охрану жизни и здоровья, в соответствии с локальными нормативными актами Исполнителя.</p>
                
                <p><b>5.1.8.	Отчислить Заказчика:</b></p>
                <p>
                    ● при наличии заявления Заказчика об отчислении по собственному желанию;<br/>
                    ● в связи с завершением обучения по Курсу;<br/>
                    ● в связи с однократным грубым или неоднократным нарушением Заказчиком условий Договора.
                </p>

                <p>5.2.	Заказчик обязан:</p>
                <p>5.2.1.	Соблюдать требования ч. 1 ст. 43 Закона</p>
                <p>5.2.2.	Ознакомиться до зачисления на Курс с локальными нормативными актами Исполнителя.</p>
                <p>Акцептуя настоящую Оферту, Заказчик подтверждает, что ознакомлен и согласен с локальными нормативными актами Исполнителя, условиями использования Сайта и учебным планом.</p>
                <p>5.2.3.	Зарегистрироваться на Сайте.</p>
                <p>5.2.4.	Выполнять в установленные сроки практические задания, предусмотренные Курсом.</p>
                <p>5.2.5.	Своевременно предоставлять все необходимые сведения и документы, извещать об изменении своих контактных и персональных данных в течение трех рабочих дней.</p>
                <p>4.2.6.	Бережно использовать имущество Исполнителя.</p>
                <p>5.2.7.	Предоставить Исполнителю необходимую персональную информацию, включающую в себя:</p>
                <p>
                    • ФИО Заказчика;<br/>
                    • номер телефона Заказчика;<br/>
                    • адрес места жительства Заказчика; адрес электронной почты.<br/>
                    • при начислении Заказчику баллов непрерывного медицинского образования (НМО) - паспортные данные, а также иную запрошенную Исполнителем информацию и данные. 
                </p>
    
                <p><b>6.	Финансовые условия</b></p>
                <p>6.1.	Стоимость доступа к Материалам и образовательных услуг, предусмотренных настоящим Договором, зависит от конкретного Курса, выбранного Заказчиком, и указывается на Сайте.</p>
                <p>6.2.	Увеличение стоимости платных образовательных услуг или доступа к Материалам после заключения Договора не допускается.</p>
                <p>6.3.	Заказчик оплачивает стоимость Курса, включающую плату за доступ к Материалам и стоимость образовательных услуг путем полной предоплаты. </p>
                <p>6.4.	Стоимость Курса оплачивается Заказчиком в адрес Исполнителя. Исполнитель из стоимости Курса удерживает лицензионное вознаграждение за доступ Заказчика к Материалам и осуществляет перечисление Исполнителю части стоимости Курса, соответствующей Стоимости образовательных услуг, в порядке, определяемом Исполнителем самостоятельно. </p>
                <p>6.5.	Стоимость образовательных услуг составляет 10% (Десять процентов) стоимости Курса, если соглашение между Исполнителем не предусмотрен больший процент. </p>

                <p><b>7.	Интеллектуальная собственность</b></p>
                <p>7.1.	В рамках обучения на Курсе Исполнитель предоставляет Заказчику доступ к Материалам, в связи с чем Заказчик обязан:</p>
                <p>
                    ●	воздерживаться от любых действий, которые нарушают права Исполнителя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Исполнителя без письменного разрешения Исполнителя;<br/>
                    ●	немедленно сообщать Исполнителю о любых, ставших известными, фактах нарушения исключительных прав Исполнителя;<br/>
                    ●	не предоставлять свои аутентификационные данные для доступа в Личный кабинет на Сайте третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: allnurses@allnurses.ru. До момента отправки указанного извещения все действия, совершенные с использованием Личного кабинета, считаются совершенными Заказчиком.
                </p>
                <p>7.2.	Использование Заказчиком Сайта, его содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Исполнителем технических решений не означает передачи (отчуждения) Заказчику и/или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.</p>
                <p>7.3.	К конфиденциальной относится любая информация, относящаяся к процессу оказания Услуг Исполнителем, неопубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания Услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия соответствующего Исполнителя.</p>

                <p><b>8.	Срок действия Договора. Порядок изменения и расторжения Договора</b></p>
                <p>8.1.	Договор действует с даты его заключения (даты Акцепта) и до даты отчисления Заказчика, а в части денежных обязательств – до полного их исполнения Сторонами.</p>
                <p>8.2.	Договор может быть расторгнут по соглашению Сторон.</p>
                <p>8.3.	Договор может быть расторгнут в одностороннем порядке по инициативе Исполнителя в случаях, предусмотренных п. 4.1 Договора. В этом случае Исполнитель направляет Заказчику уведомление в электронной форме (с указанием основания для отчисления).</p>
                <p>8.4.	Договор может быть в любое время расторгнут досрочно в одностороннем порядке по инициативе Заказчика путем направления Исполнителю заявления об отчислении. Заявление об отчислении направляется по адресу электронной почты allnurses@allnurses.ru.</p>
                <p>8.5.	Договор расторгается на основании приказа Исполнителя об отчислении Заказчика. Права и обязанности Заказчика по Договору прекращаются с даты его отчисления.</p>
                <p>8.6.	Действие Договора прекращается досрочно по обстоятельствам, не зависящим от воли Заказчика и Исполнителя.</p>
                <p>8.7.	В случае досрочного расторжения Договора стоимость лицензионного вознаграждения за доступ к Материалам, который был предоставлен Заказчику, не возвращается. Стоимость образовательных услуг возвращается в случае, если доступ Заказчика к Курсу длился менее 7 дней. </p>
                <p>8.8.	Договор считается расторгнутым с момента издания Исполнителем приказа об отчислении Заказчика, а в части денежных обязательств – в момент полного их исполнения.</p>

                <p><b>9. Ответственность Сторон</b></p>
                <p>9.1.	При неисполнении Заказчиком обязательств, предусмотренных пунктом 7.1 Договора, и выявлении Исполнителем факта доступа третьих лиц к Материалам Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить в пользу Исполнителя штраф в размере 1 000 000 (Одного миллиона) рублей.</p>
                <p>9.2.	Если Исполнитель нарушил сроки оказания образовательных услуг, Заказчик вправе по своему выбору:</p>
                <p>
                    ● назначить Исполнителю новый срок оказания образовательной услуги;<br/>
                    ● расторгнуть Договор.
                </p>
                <p>9.3.	Заказчик принимает на себя ответственность в полном объеме за последствия установки и использования программного обеспечения третьих лиц и соглашается с тем, что сведения, представленные Исполнителем, включая сведения, представленные и/или полученные посредством удаленного доступа Исполнителя к компьютеру Заказчика, носят исключительно рекомендательный характер. Исполнитель не несет ответственности за установку и использование Заказчиком программного обеспечения и/или иных объектов третьих лиц.</p>
                <p>9.4.	При причинении Заказчиком вреда имуществу Исполнителя, Заказчик обязан по письменному требованию Исполнителя возместить стоимость такого имущества (его составной части).</p>
                
                <p><b>10.	Заключительные положения</b></p>
                <p>10.1.	Стороны договорились, что если иное прямо не предусмотрено законодательством или настоящим Договором, любые сообщения, уведомления и документы в виде хорошо читаемых скан- или фотокопий, направленных Сторонами исключительно с электронных адресов: Исполнителя – указанного в разделе 10 настоящего Договора, а также любой электронной почте в домене @allnurses.ru, </p>
                <p>Заказчика — указанного при регистрации Личного кабинета, </p>
                <p>обладают юридической силой соответствующих документов на бумажном носителе и создают для Сторон все права и обязанности, предусмотренные настоящим Договором.</p>
                <p>10.2.	Стороны гарантируют работоспособность указанных в Договоре адресов электронной почты и самостоятельно несут риски, связанные с неполучением, несвоевременным получением или отправкой сообщений, неознакомлением с их содержанием в связи с любыми техническими неисправностями в работе электронной почты, отсутствием доступа к ней по любым причинам, включая отсутствие доступа в Интернет, результатами специальной сортировки сообщений, в частности, попаданием сообщений в папку «Спам» либо аналогичную.</p>
                <p>10.3.	В случае изменения ФИО, места жительства или банковских реквизитов любой Стороны она обязана уведомить об этом другую Сторону не менее чем за 3 (три) рабочих дня со дня наступления указанных обстоятельств любыми доступными способами, позволяющими подтвердить получение такого уведомления другой Стороной. Любые убытки, возникшие у Стороны, не исполнившей и/или несвоевременно исполнившей обязательство по уведомлению, связанные с отсутствием такого уведомления, не подлежат компенсации другой Стороной.</p>
                <p>10.4.	В случае возникновения любых разногласий между Заказчиком и Исполнителем относительно исполнения каждой из сторон условий Договора, а также любых иных разногласий, такие разногласия могут быть урегулированы с применением досудебного претензионного порядка. Исполнитель обязуется направить Заказчику претензию в электронном виде на адрес электронной почты, указанный Заказчиком при регистрации Личного кабинета. Заказчик вправе направить Исполнителю претензию на юридический адрес Заказчика. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения.</p>
                <p>10.5.	Заказчик даёт свое согласие Исполнителю на обнародование и дальнейшее использование изображения Заказчика в фото-, видеоматериалах, равно как и зафиксированного в независимых друг от друга кадрах таких видеоматериалов, а также зафиксированного в любых иных объектах изображении в целях размещения такого изображения на официальных сайтах Исполнителя, в информационных материалах Исполнителя и любых иных целях, связанных с образовательным процессом и не противоречащих действующему законодательству, в том числе для загрузки фотографии в личный кабинет Заказчика и участия в вебинарах в рамках Курса. Настоящее согласие действует с даты заключения Договора и распространяется на любые объекты, созданные Исполнителем в период обучения Заказчика, а также полученные от Заказчика этот период. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 10 Договора.</p>

                <p><b>11.	Реквизиты Исполнителя</b></p>
                <p>
                    АНО ДПО «Внимание и забота»
                    ИНН 9709002692,
                    КПП 770901001,
                    ОГРН 1177700009277, 
                    Юридический адрес: 109029, город Москва, Нижегородская ул, д. 32 стр. 4, помещ. 113
                    Банк ООО "Банк Точка", г. Москва
                    к/с 30101810745374525104
                    БИК 044525104
                    e-mail: allnurses@allnurses.ru 
                    тел.: +7 495 363 7174	
                    Генеральный директор - Волков Александр Сергеевич  
                </p>
            </div>
        </section>
    )
}