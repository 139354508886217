import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { PopupInfo } from "../../component/PopupInfo"
import { Helmet } from "react-helmet"

export const UpdatePassPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")
    const [form, setForm] = useState({ 
        email: ""
    })

    useEffect(()=>{
        if(auth.user.userToken != ""){
            window.location.replace('/account/');
        }
    },[])
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        auth.user.SendPassword(form.email).then(function(response){
            if(response.error == false){
                setForm({
                    email: ""
                })
            }
            setOpenPopupText(response.msg)
            setOpenPopup(true)
        }).catch(function(error){
            alert("error")
        })
    }
    
    return(
        <>
            <Helmet>
                <title>Забыли пароль - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="form-login">
                <div className="content">
                    <div className="form">
                        <h2>Забыли пароль</h2>
                        <h3>Укажите email для восстановления пароля</h3>
                        <form onSubmit={handleSubmit}>
                            <label>
                                <input type="email" maxLength={255} placeholder="Email" required name="email" value={form.email} onChange={changeHandler}  />
                            </label>
                            <div>
                                <button>Обновить</button>
                                <Link to={"/login/"}>Войти</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {
                openPopup ? (
                    <PopupInfo text={openPopupText} close={setOpenPopup} />
                ):""
            }
        </>
    )
}