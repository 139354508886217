import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const CourseModule = (props: any) => {
    const auth = useContext(GlobalContext)
    const [openItem, setOpenItem] = useState(false)
    const [moduleFavorite, setModuleFavorite] = useState(auth.user.favoriteModule.includes(props.item.pk))
    const [moduleCart, setModuleCart] = useState(auth.user.cart.includes(props.item.pk))


    return(
        <div className={`course-module__item ${openItem ? "active":""}`}>
            <div className="pls" onClick={()=>{setOpenItem(!openItem)}}>

            </div>
            <div className="contacts-qw__title course-module__title">
                <div className="row">
                    <div className="col-md-7" style={{cursor: "pointer"}} onClick={()=>{setOpenItem(!openItem)}}>
                        <div className="grid">
                            <div>
                                <span className="mod">Модуль {props.item.sort} / Уроков {props.item.lesson_module.length}</span>
                                {props.item.title}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="grid">
                            <div className="course-module__buy-info">
                                <div className="grid">
                                    <div className="courses-price__cost">
                                        {
                                            props.item.finalCost == 0 ? (
                                                <>Бесплатно</>
                                            ):(
                                                props.item.cost != props.item.finalCost ? (
                                                    <>   
                                                        <span>{props.item.cost}₽</span>
                                                        {props.item.finalCost}₽
                                                    </>
                                                ):(
                                                    <>
                                                        {props.item.finalCost}₽
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="grid">
                                    {
                                        auth.user.module.includes(props.item.pk) ? (
                                            <button style={{backgroundColor: "#f26421", color: "white"}}>Приобретен</button>
                                        ):(  
                                            <button 
                                                className={
                                                    moduleCart ? "active" : ""
                                                }
                                                onClick={()=>{
                                                    if(!moduleCart){
                                                        setModuleCart(true)
                                                        auth.user.cart.push(props.item.pk)
                                                    }else{
                                                        setModuleCart(false)
                                                        const index = auth.user.cart.indexOf(props.item.pk)
                                                        if (index !== -1) {
                                                            auth.user.cart.splice(index, 1)
                                                        }
                                                    }
                                                    auth.user.setCart()

                                                    props.setCourseInCart(props.CourseStatus())
                                                    console.log(auth.user.cart)
                                                }}
                                            >
                                                {!moduleCart ? "В корзину" : "В корзине"}
                                            </button>
                                        )
                                    }
                                </div>
                                <div className="grid">
                                    <div>
                                        <svg 
                                            style={{cursor: "pointer"}}
                                            width="26" 
                                            height="26" 
                                            viewBox="0 0 26 26" 
                                            fill="none" 
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={()=>{ 
                                                if(!moduleFavorite){
                                                    setModuleFavorite(true)
                                                    auth.user.favoriteModule.push(props.item.pk)
                                                }else{
                                                    setModuleFavorite(false)
                                                    const index = auth.user.favoriteModule.indexOf(props.item.pk)
                                                    if (index !== -1) {
                                                        auth.user.favoriteModule.splice(index, 1)
                                                    }
                                                }
                                                auth.user.setFavorite()
                                                console.log(auth.user.favoriteModule)
                                            }}
                                        >
                                            <path 
                                                stroke={!moduleFavorite ? "" : "#f26421" }
                                                fill={!moduleFavorite ? "#7A7A8A" : "#f26421" }
                                                d="M9.70862 20.1781L10.1729 19.5891L9.70862 20.1781ZM13.0001 5.65036L12.4597 6.17046C12.6011 6.31736 12.7962 6.40036 13.0001 6.40036C13.204 6.40036 13.3991 6.31736 13.5404 6.17046L13.0001 5.65036ZM16.2915 20.1781L16.7558 20.7671L16.2915 20.1781ZM10.1729 19.5891C8.53469 18.2977 6.73233 17.0286 5.30316 15.4193C3.8997 13.8389 2.91675 11.9899 2.91675 9.58987H1.41675C1.41675 12.458 2.61098 14.6467 4.18159 16.4153C5.72648 18.1549 7.69506 19.5458 9.24431 20.7671L10.1729 19.5891ZM2.91675 9.58987C2.91675 7.23638 4.2467 5.25951 6.06715 4.42738C7.84032 3.61686 10.2142 3.83751 12.4597 6.17046L13.5404 5.13026C10.9111 2.39845 7.86835 1.95477 5.44356 3.06315C3.06605 4.14991 1.41675 6.67518 1.41675 9.58987H2.91675ZM9.24431 20.7671C9.79981 21.205 10.3922 21.6687 10.9915 22.019C11.5906 22.369 12.268 22.6497 13.0001 22.6497V21.1497C12.6488 21.1497 12.2429 21.0129 11.7483 20.7239C11.2539 20.435 10.7422 20.0379 10.1729 19.5891L9.24431 20.7671ZM16.7558 20.7671C18.3051 19.5458 20.2737 18.1549 21.8186 16.4153C23.3892 14.6467 24.5834 12.458 24.5834 9.58987H23.0834C23.0834 11.9899 22.1005 13.8389 20.697 15.4193C19.2678 17.0286 17.4655 18.2977 15.8272 19.5891L16.7558 20.7671ZM24.5834 9.58987C24.5834 6.67518 22.9341 4.14991 20.5566 3.06315C18.1318 1.95477 15.0891 2.39845 12.4597 5.13026L13.5404 6.17046C15.7859 3.83751 18.1598 3.61686 19.933 4.42738C21.7535 5.25951 23.0834 7.23638 23.0834 9.58987H24.5834ZM15.8272 19.5891C15.2579 20.0379 14.7462 20.435 14.2519 20.7239C13.7573 21.0129 13.3513 21.1497 13.0001 21.1497V22.6497C13.7321 22.6497 14.4096 22.369 15.0087 22.019C15.608 21.6687 16.2004 21.205 16.7558 20.7671L15.8272 19.5891Z"  
                                            />
                                        </svg>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contacts-qw__desc">
                {props.item.miniDesc}
            </div>
        </div>
    )
}