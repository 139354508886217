import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import twoblockI1 from '../../images/two-blockI1.png'
import twoblockI2 from '../../images/two-blockI2.png'
import itemcourses from '../../images/item_courses.png'
import doingwell1 from '../../images/doing-well1.svg'
import doingwell2 from '../../images/doing-well2.svg'
import doingwell3 from '../../images/doing-well3.svg'
import doingwell4 from '../../images/doing-well4.svg'
import contacts from '../../images/contacts.svg'
import vk from '../../images/vk.svg'
import tg from '../../images/tg.svg'
import pgrants_1 from '../../images/pgrants_1.png'
import pgrants_2 from '../../images/fond.png'

import arrowleft from '../../images/arrow_left.svg'
import arrowright from '../../images/arrow_right.svg'
import { Link } from "react-router-dom";
import { OpenerItem } from "../../component/OpenerItem";
import { CoursesSlider } from "../../component/CoursesSlider";
import { ReviewsSlider } from "../../component/ReviewsSlider";
import { ReviewsPopup } from "../../component/ReviewsPopup";
import { Helmet } from "react-helmet";
import axios from "axios";
import { host, hostImg } from "../../classes";


function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowright} 
            onClick={onClick}
        />
    );
}
  
function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowleft} 
            onClick={onClick}
        />
    );
}

export const MainPage = () => {
    const partners = [
        pgrants_1,
        pgrants_2,
        pgrants_1,
        pgrants_2,
        pgrants_1,
        pgrants_2
    ]

    const [parts, setParts] = useState<[{
        id: number,
        sort: string,
        image: string,
        url: string
    }]>()
    const LoadPart = async () => {
        await axios.get(`${hostImg}get-partners/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            setParts(res.data)
            console.log(res.data)
        }).catch(function (error) { 
            console.log(error)
        }) 
    }
    useEffect(()=>{
        LoadPart()
    },[])

    const [openReview, setOpenReview] = useState(false)
    const [questions, setQuestions] = useState([
        {
            title: "Будут ли выдаваться баллы НМО?",
            desc: (<>Скоро на наших курсах появится возможность получать баллы непрерывного медицинского образования. Следите за новостями на сайте <Link to="https://alsfund.ru" target="_blank">alsfund.ru</Link> или в соцсетях фонда</>)
        },
        {
            title: "На какое время будет предоставляться доступ?",
            desc: "Доступ к курсу после покупки останется у вас даже после прохождения"
        },
        {
            title: "Будут ли очные программы?",
            desc: (<>Фонд «Живи сейчас» регулярно проводит очные образовательные мероприятия для медицинских специалистов. Следите за новостями на сайте <Link to="https://alsfund.ru" target="_blank">alsfund.ru</Link> или в соцсетях фонда</>)
        },
        {
            title: "Есть ли еще какие-либо курсы у фонда?",
            desc: "Мы постоянно работаем над тем, чтобы создавать больше образовательных проектов, подписывайтесь на соцсети фонда «Живи сейчас», чтобы следить за актуальной информацией. Наши курсы мы будем публиковать здесь"
        },
        {
            title: "Будет ли поддержка в процессе обучения?",
            desc: "Вы всегда сможете задать вопросы в онлайн-чате курса в личном кабинете"
        },
        {
            title: "Как получить сертификат?",
            desc: "Сертификат смогут получить на почту все учащиеся, кто правильно ответит на 80% вопросов и больше"
        }
    ]) 
    return(
        <>
            <Helmet>
                <title>Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="banner">
                <div className="content">
                    <h1>   
                        Образовательный портал
                    </h1>
                    <div className="banner-btns">
                        <div className="grid">
                            <div className="row">
                                <div className="col-md-8">
                                    <p>
                                        Обучаем специалистов медицинского и социального профиля в области ведения, наблюдения и ухода за пациентами с паллиативным статусом
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className='grid'>
                                        <Link to="/courses/">Выбрать курс</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="two-block">
                <div className="content">
                    <div className="row">
                        <div className="col-md-6" style={{marginBottom: "20px"}}>
                            <div className="two-block__wrapper" style={{backgroundImage: `url('${twoblockI1}')`}}>
                                <h2>
                                    Более 4000 <span></span>
                                </h2>
                                <p>
                                    Медицинских специалистов, социальных работников и ухаживающих прошли обучение
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="two-block__wrapper" style={{backgroundImage: `url('${twoblockI2}')`}}>
                                <h2 style={{color: "black"}}>
                                    Более 1000 <span style={{color: "black"}}></span>
                                </h2>
                                <p style={{color: "black"}}>Обучающих и информационных материалов создано командой фонда за 9 лет работы</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-courses">
                <div className="content">
                    <h2 className="block-title">Наши курсы</h2>
                    <CoursesSlider />
                </div>
            </section>

            <section className="video-lesson">
                <div className="content">
                    <h2 className="block-title">О фонде</h2>
                    <div></div>
                    <iframe src="https://www.youtube.com/embed/Wy9zZWT3PMw?si=na0YY4anF1gOi4-t" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
                </div>
            </section>

            <section className="corp">
                <div className="content">
                    <div className="corp-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Корпоративным партнерам</h2>
                                <p>Обучаем сотрудников медицинских и социальных учреждений на специальных условиях</p>
                                <div className="cormmcent">
                                    <Link to="/corporate/">Узнать подробнее</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="doing-well">
                <div className="content">
                    <h2 className="block-title">Почему у нас хорошо?</h2>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="doing-well__wrapper">
                                <img src={doingwell4} />
                                <h3>9 лет работы с тяжелобольными людьми</h3>
                                <p>Знаем всё об уходе за лежачими пациентами</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="doing-well__wrapper">
                                <img src={doingwell1} />
                                <h3>Авторы курсов</h3>
                                <p>Действующие медицинские специалисты с многолетним практическим опытом</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="doing-well__wrapper">
                                <img src={doingwell2} />
                                <h3>Выдаем сертификаты об обучении</h3>
                                <p>Только после сдачи тестирования и проверки кураторов курса</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="doing-well__wrapper">
                                <img src={doingwell3} />
                                <h3>Обучаем онлайн</h3>
                                <p>Из любой точки России и мира, вне зависимости от часового пояса</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="teacher">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="teacher-grad">
                                <div className="grid">
                                    <div>
                                        <h3>Экспертная поддержка</h3>
                                        <p>Наши специалисты на связи на каждом этапе, вовремя помогут решить технические проблемы и ответят на вопросы в процессе обучения</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="teacher-content">
                                <div className="grid">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h2>Преподаватели</h2>
                                            <p>Обучающие курсы подготовлены экспертами благотворительного фонда «Живи сейчас», а именно практикующими специалистами, имеющими большой опыт ведения пациентов с БАС на разных стадиях болезни.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="reviews">
                <div className="content">  
                    <h2 className="block-title">
                        Отзывы
                        <span onClick={()=>{setOpenReview(true)}}>
                            Смотреть все отзывы
                            <svg onClick={()=>{alert("test")}} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10L10 1M10 1H2.46939M10 1V8.53061" stroke="#F26421" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </h2>
                    <ReviewsSlider />
                </div>
            </section>

            {
                openReview ? (
                    <ReviewsPopup setOpenReview={setOpenReview} />
                ):""
            }

            <section className="partner">
                <div className="content">
                    <h2 className="block-title">Партнеры</h2>
                    
                    <Slider {
                        ...{
                            dots: true, 
                            infinite: true, 
                            speed: 500, 
                            slidesToShow: 5, 
                            slidesToScroll: 1, 
                            nextArrow: <SampleNextArrow />, 
                            prevArrow: <SamplePrevArrow />,
                            responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                    }
                                },
                                {
                                    breakpoint: 960,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 1,
                                        arrows: false
                                    }
                                },
                                {
                                    breakpoint: 760,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: false
                                    }
                                }
                            ]
                        }
                    }>
                        {
                            parts ? (
                                parts.map((item, index) => {
                                    return(
                                        <div>
                                            <div className="partner-wrapper">
                                                <div className="grid">
                                                    <img src={`${hostImg}${item.image}`} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        }
                    </Slider>
                </div>
            </section>

            <section className="contacts">
                <div className="content">
                    <div className="row">
                        <div className="col-md-4 order-2 order-md-1">
                            <div className="contacts-wrapper">
                                <img src={contacts} />
                                <h2>Свяжитесь<br/>с нами</h2>

                                <div className="contacts-cont">
                                    <span>Электронная почта</span>
                                    <Link to="mailto:info@alsfund.ru">info@alsfund.ru</Link>
                                </div>

                                <div className="contacts-cont">
                                    <span>Телефон</span>
                                    <Link to="tel:+74959685626">+7 (495) 968-56-26</Link>
                                </div>

                                <div className="contacts-cont">
                                    <span>Социальные сети</span>
                                    <Link to="https://vk.com/als.russia" target="_blank" style={{marginRight: 5}}>
                                        <img src={vk} />
                                    </Link>
                                    <Link to="https://t.me/alsfund" target="_blank" style={{marginLeft: 5}}>
                                        <img src={tg} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 order-1 order-md-2">
                            <div className="contacts-wrapper contacts-qw">
                                <h3>Часто задаваемые вопросы</h3>
                                <div className="contacts-qw__wrapper">
                                    {
                                        questions.map((item, index) => {
                                            return(
                                                <OpenerItem 
                                                    title={<div className="contacts-qw__title">{item.title}</div>} 
                                                    desc={
                                                        <div className="contacts-qw__desc">{item.desc}</div>
                                                    }
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}