import footerlogo from '../../images/footer-logo.svg'
import footervk from '../../images/footer-vk.svg'
import footertg from '../../images/footer-tg.svg'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return(
        <>
            <footer>
                <div className="content">
                    <div className="row footer-menu">
                        <div className="col-md-3">
                            <Link to="/">
                                <img className="form-logo" src={footerlogo} />
                            </Link>
                            <p
                                style={{
                                    color: "white"
                                }}
                            >
                                ИНН 7719417621<br/>
                                ОГРН 1157700009994
                            </p>
                        </div>
                        <div className="col-md-3">
                            <h3>Навигация</h3>
                            <ul>
                                <li>
                                    <Link to="https://alsfund.ru/" target='_blank'>Основной сайт фонда «Живи сейчас»</Link>
                                </li>
                                <li>
                                    <Link to="https://als-info.ru/" target='_blank'>Информационный портал о БАС</Link>
                                </li>
                                <li>
                                    <Link to="https://als-help.ru/" target='_blank'>Сайт о сложных решениях при БАС</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Контакты</h3>
                            <ul>
                                <li>
                                    <Link to="tel:+74959685626">+7 (495) 968-56-26</Link>
                                </li>
                                <li>
                                    <Link to="mailto:info@alsfund.ru">info@alsfund.ru</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Мы в соцсетях</h3>
                            <div className="footer-soc">
                                <Link to="https://vk.com/als.russia" target='_blank'>
                                    <img src={footervk} />
                                </Link>
                                <Link to="https://t.me/alsfund" target="_blank">
                                    <img src={footertg} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-polit">
                        <div className="row">
                            <div className="col-md-4">
                                <Link to="/privacy-policy/" target='_blank'>Политика конфиденциальности</Link>
                            </div>
                            <div className='col-md-4' style={{textAlign: "center"}}>
                                <Link to="/user-agreement/" target='_blank'>Пользовательское соглашение</Link>
                            </div>
                            <div className="col-md-4 right">
                                <Link to="/offer/" target='_blank'>Публичная оферта</Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-underline">
                        <div className="row">
                            <div className="col-md-8">
                                Фонд существует, чтобы развивать в России все виды помощи больным БАС, а также пациентам с другими нейромышечными заболеваниями. Мы помогаем пациентам, их близким и тем, кто помогает. Мы делаем все возможное, чтобы общество узнало о проблемах людей с этими диагнозами.
                            </div>
                            <div className="col-md-4">
                                <div className="grid">
                                    <div className="right">2024 © БФ «Живи сейчас»</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}