import { useContext, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { Link } from "react-router-dom"

export const Module = (props: any) => {
    const auth = useContext(GlobalContext)
    const [openItem, setOpenItem] = useState(false)

    return(
        <div className={`course-module__item ${openItem ? "active":""}`}>
            <div className="contacts-qw__title course-module__title">
                <div className="row">
                    <div className="col-md-7" style={{cursor: "pointer"}} onClick={()=>{setOpenItem(!openItem)}}>
                        <div className="grid">
                            <div>
                                <span className="mod">Модуль {props.item.sort} / Уроков {props.item.lesson_count}</span>
                                {props.item.title}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="grid">
                            <div className="course-module__buy-info buyItem"> 
                                <div className="grid">
                                    <Link 
                                        style={
                                            {
                                                color: "#0c5da4",
                                                textDecoration: "none"
                                            }
                                        }
                                        to={`/account/${props.item.course.pk}/${props.item.pk}/`}
                                    >
                                        Подробнее
                                    </Link>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contacts-qw__desc">
                {props.item.miniDesc}
            </div>
        </div>
    )
}