import { useEffect, useState } from "react"
import { ReviewsList } from "../../classes"
import logoLoader from '../../images/logoLoader.svg'
import { ReviewsListItem } from "../ReviewsListItem"

export const ReviewsPopup = (props: any) => {
    const [load, setLoad] = useState(true)
    const [reviews, setReviews] = useState(new ReviewsList(25))
    
    useEffect(()=>{
        reviews.getData(1, props.course ? props.course : 0).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

        
    return(
        <div className="popup-review">
            <div>
                <div className="popup-review__head">
                    Отзывы 
                    <svg onClick={()=>{props.setOpenReview(false)}} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19" cy="19" r="18.5" stroke="#F26421" />
                        <path d="M23.9497 14.0505L14.0502 23.95" stroke="#F26421" stroke-linecap="round" />
                        <path d="M14.0503 14.0505L23.9498 23.95" stroke="#F26421" stroke-linecap="round" />
                    </svg>
                </div>

                <div className="popup-review__content">
                    {
                        load ? (
                            <div className="load-block">
                                <img src={logoLoader} />
                                <p>Загрузка</p>
                            </div>
                        ):(
                            reviews.list.length ? (
                                reviews.list.map((item, index) => {
                                    return(
                                        <>
                                            <ReviewsListItem item={item} col={12} />
                                        </>
                                    )
                                })
                            ):""
                        )
                    }
                </div>
            </div>
        </div>
    )
}