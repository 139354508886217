import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { GlobalContext } from "../../../context/GlobalContext"
import { PurchCourse } from "../../../classes/purch"
import { Loader } from "../../../component/Loader"
import { OpenerItem } from "../../../component/OpenerItem"
import { Helmet } from "react-helmet"

export const PurchasedCoursePage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const { pk } = useParams()
    const [course, setCourse] = useState(new PurchCourse({
        pk: Number(pk),
        title: "",
        image: "",
        miniDesc: ""
    }))

    useEffect(()=>{
        setLoad(true)
        course.getData(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){ 
            alert("error")
        })
    },[])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <Helmet>
                <title>Курсы - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="purchased-course">
                <div className="content">
                    <Link 
                        to={`/account/?p=course`}
                        style={{
                            textDecoration: "none",
                            color: "#0c5da4",
                            fontSize: 18,
                            marginBottom: "30px",
                            display: "inline-block"
                        }}
                    >
                        {"< Назад"}
                    </Link>
                    <h2 className="block-title">{course.title}</h2>
                    <div className="purchased-course__desc" dangerouslySetInnerHTML={{ __html: course.miniDesc }} />
 

                    <div className="purchased-course__modules">
                        <h3>
                            Модули курса 
                            <span>
                                (<Link to={`/courses/${pk}/`}>Все модули курса</Link>)
                            </span>
                        </h3>
                        <div className="purchased-course__wrapper">
                            {
                                course.modules ? (
                                    course.modules.map((item, index) => {
                                        return(
                                            <OpenerItem 
                                                title={
                                                    <div className="contacts-qw__title course-module__title">
                                                        <div className="row">
                                                            <div className="col-md-7" style={{cursor: "pointer"}}>
                                                                <div className="grid">
                                                                    <div>
                                                                        <span className="mod">{item.completed ? item.completed.length : 0}/{item.lesson_count} уроков пройдено</span>
                                                                        {item.title} 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="grid">
                                                                    <div className="course-module__buy-info buyItem"> 
                                                                        <div className="grid">
                                                                            <Link 
                                                                                style={
                                                                                    {
                                                                                        color: "#0c5da4",
                                                                                        textDecoration: "none"
                                                                                    }
                                                                                }
                                                                                to={`/account/${pk}/${item.pk}/`}
                                                                            >
                                                                                Подробнее
                                                                            </Link>
                                                                        </div>       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                } 
                                                desc={
                                                    <div className="contacts-qw__desc">
                                                        {item.miniDesc}
                                                    </div>
                                                }
                                            />                                        
                                        )
                                    })
                                ):""
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}