export const PrivacyPage = () => {
    return(
        <section>
            <div className="content" style={{paddingTop: 50, paddingBottom: 50}}>
                <h1 className="title" style={{textAlign: "center"}}>
                    ПОЛИТИКА
                </h1>
                <p style={{
                    textAlign: "center"
                }}>
                    конфиденциальности сайта kurs.als-info.ru и обработки персональных данных<br/>
                    БФ «Живи сейчас»<br/>
                    (далее – БФ)
                </p>

                <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                <p>1.1. Настоящая Политика БФ в отношении конфиденциальности сайта kurs.als-info.ru и обработки персональных данных БФ (далее – Политика): </p>
                <p>1.1.1.	Разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее – Закон о персональных данных) в целях обеспечения защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
                <p>1.1.2.	Регулирует использование Cookies и иных технологий, для целей обеспечения законного и безопасного режима автоматического сбора и обработки данных пользователей Сайта.</p>
                <p>1.2. Политика действует в отношении всех персональных данных, которые обрабатывает БФ (далее – Оператор).</p>
                <p>1.3. Политика распространяется на отношения в области обработки персональных данных, возникшие у Оператора как до, так и после утверждения настоящей Политики.</p>
                <p>1.4. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте Оператора https://kurs.als-info.ru.</p>
                <p>
                    1.5. Основные понятия, используемые в Политике:<br/>
                    <b>Cookies</b> – компьютерный файл, содержащий данные, среди которых включая, но не ограничиваясь может содержаться уникальный анонимный идентификатор, посылаемый программному обеспечению и оборудованию Пользователя Сайтом и сохраняемый на оборудовании Пользователя, хранящий информацию об истории взаимодействия Пользователя с Сайтом, а также иные сведения о Пользователе, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта;<br/>
                    <b>IP-адрес</b> — уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ к Продуктам Владельца Сайта.<br/>
                    <b>Автоматизированная обработка персональных данных</b> – обработка персональных данных с помощью средств вычислительной техники;<br/>
                    <b>Администратор или Владелец Сайта</b> – Благотворительный фонд помощи людям с боковым амиотрофическим склерозом и другими нейромышечными заболеваниями «Живи сейчас», ИНН 7719417621, ОГРН 1157700009994 от 01 июля 2015 г., тел. +7 (495) 968-56-26, e-mail: info@alsfund.ru;<br/>
                    <b>Блокирование персональных данных</b> – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);<br/>
                    <b>Информационная система персональных данных</b> – совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;<br/>
                    <b>Обезличивание персональных данных</b> – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;<br/>
                    <b>Обработка персональных данных</b> – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе:<br/>
                    •	 сбор;<br/>
                    •	запись;<br/>
                    •	систематизацию;<br/>
                    •	накопление;<br/>
                    •	хранение;<br/>
                    •	уточнение (обновление, изменение);<br/>
                    •	извлечение;<br/>
                    •	использование;<br/>
                    •	передачу (распространение, предоставление, доступ);<br/>
                    •	обезличивание;<br/>
                    •	блокирование;<br/>
                    •	удаление;<br/>
                    •	уничтожение;<br/>
                    <b>Оператор персональных данных (оператор)</b> – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;<br/>
                    <b>Персональные данные</b> – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);<br/>
                    <b>Пользователь</b> – любое физическое лицо и/или юридическое лицо, в том числе посетитель Сайта;<br/>
                    <b>Предоставление персональных данных</b> – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;<br/>
                    <b>Продукты Владельца Сайта</b> – любые информационные услуги и сервисы, предоставляемые посредством Сайта;<br/>
                    <b>Распространение персональных данных</b> – действия, направленные на раскрытие персональных данных неопределенному кругу лиц;<br/>
                    <b>Сайт</b> – это совокупность программных и аппаратных средств для ЭВМ, информации, элементов дизайна, текста, графических изображений, иллюстраций, видео, скриптов, программ, музыки, звуков и других объектов, доступная в сети Интернет по сетевому адресу https://kurs.als-info.ru. Сайт принадлежит Владельцу Сайта;<br/>
                    <b>Трансграничная передача персональных данных</b> – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу;<br/>
                    <b>Уничтожение персональных данных</b> – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.<br/>
                </p>
                <h3>2. ОСНОВНЫЕ ПРАВА И ОБЯЗАННОСТИ ОПЕРАТОРА В ОТНОШЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ.</h3>
                <p>
                    2.1. Оператор имеет право:<br/>
                    1)	самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами;<br/>
                    2)	поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом о персональных данных и настоящей Политикой;<br/>
                    3)	в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных.
                </p>
                <p>
                    2.2. Оператор обязан:<br/>
                    1)	обеспечивать конфиденциальность и безопасность персональных данных субъектов персональных данных;<br/>
                    2)	организовывать обработку персональных данных в соответствии с требованиями Закона о персональных данных;<br/>
                    3)	отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;<br/>
                    4)	сообщать в уполномоченный орган по защите прав субъектов персональных данных (Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по запросу этого органа необходимую информацию в течение 30 дней с даты получения такого запроса.
                </p>

                <h3>3. ОСНОВНЫЕ ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ.</h3>

                <p>
                    3.1. Субъект персональных данных имеет право:<br/>
                    1)	получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;<br/>
                    2)	требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;<br/>
                    3)	выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;<br/>
                    4)	обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных.
                </p>
                <p>3.2. Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом, ответственным за организацию обработки персональных данных у Оператора.</p>
                <p>3.3. Ответственность за нарушение требований законодательства Российской Федерации и нормативных актов БФ в сфере обработки и защиты персональных данных определяется в соответствии с законодательством Российской Федерации.</p>
                
                <h3>4. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>4.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
                <p>4.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
                <p>
                    4.3. Обработка Оператором персональных данных осуществляется в следующих целях:<br/>
                    •	обеспечение соблюдения Конституции Российской Федерации, федеральных законов и иных нормативных правовых актов Российской Федерации;<br/>
                    •	осуществление предпринимательской деятельности;<br/>
                    •	сбор и обработка данных о Пользователях Сайта и Продуктов Владельца Сайта;<br/>
                    •	заключение и исполнение договоров на реализацию продукции Оператора; <br/>
                    •	ведение кадрового делопроизводства;<br/>
                    •	содействие работникам в трудоустройстве, получении образования и продвижении по службе, обеспечение личной безопасности работников, контроль количества и качества выполняемой работы, обеспечение сохранности имущества;<br/>
                    •	привлечение и отбор кандидатов на работу у Оператора, формирование кадрового резерва;<br/>
                    •	организация постановки на индивидуальный (персонифицированный) учет работников в системе обязательного пенсионного страхования;<br/>
                    •	заполнение и передача в органы исполнительной власти и иные уполномоченные организации требуемых форм отчетности;<br/>
                    •	осуществление гражданско-правовых отношений;<br/>
                    •	ведение бухгалтерского учета;<br/>
                    •	осуществление пропускного режима.
                </p>
                <p>4.4. Обработка персональных данных осуществляется исключительно в целях обеспечения соблюдения законов и иных нормативных правовых актов.</p>

                <h3>3. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>
                    3.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе:<br/>
                    •	Конституция Российской Федерации;<br/>
                    •	Гражданский кодекс Российской Федерации;<br/>
                    •	Трудовой кодекс Российской Федерации;<br/>
                    •	Налоговый кодекс Российской Федерации;<br/>
                    •	Федеральный закон от 12.01.1996 №7-ФЗ (ред. от 16.04.2022) «О некоммерческих организациях»;<br/>
                    •	Федеральный закон от 06.12.2011 № 402-ФЗ «О бухгалтерском учете»;<br/>
                    •	Федеральный закон от 15.12.2001 № 167-ФЗ «Об обязательном пенсионном страховании в Российской Федерации»;<br/>
                    •	иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.
                </p>
                <p>
                    3.2. Правовым основанием обработки персональных данных также являются:<br/>
                    •	договоры, заключаемые между Оператором и субъектами персональных данных;<br/>
                    •	согласия субъектов персональных данных на обработку и (или) распространение их персональных данных.
                </p>

                <h3>4. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>

                <p>4.1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки, предусмотренным в разд. 2 настоящей Политики и условиями соответствующего согласия субъект персональных данных, если применимо. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.</p>
                <p>4.2. Оператор может обрабатывать персональные данные следующих категорий субъектов персональных данных:</p>
                <p>
                    4.2.1. Кандидаты для приема на работу к Оператору:<br/>
                    •	фамилия, имя, отчество;<br/>
                    •	пол;<br/>
                    •	гражданство;<br/>
                    •	дата и место рождения;<br/>
                    •	контактные данные;<br/>
                    •	сведения об образовании, опыте работы, квалификации;<br/>
                    •	биометрические персональные данные – фотоизображение субъекта;<br/>
                    •	иные персональные данные, сообщаемые кандидатами в резюме и сопроводительных письмах.
                </p>
                <p>
                    4.2.2. Работники и бывшие работники Оператора:
                    •	фамилия, имя, отчество;<br/>
                    •	пол;<br/>
                    •	гражданство;<br/>
                    •	дата и место рождения;<br/>
                    •	биометрические персональные данные – фотоизображение субъекта;<br/>
                    •	паспортные данные;<br/>
                `   •	адрес регистрации по месту жительства;<br/>
                    •	адрес фактического проживания;<br/>
                    •	контактные данные;<br/>
                    •	индивидуальный номер налогоплательщика;<br/>
                    •	страховой номер индивидуального лицевого счета (СНИЛС);<br/>
                    •	сведения об образовании, квалификации, профессиональной подготовке и повышении квалификации;<br/>
                    •	семейное положение, наличие детей, родственные связи;<br/>
                    •	сведения о трудовой деятельности, в том числе наличие поощрений, награждений и (или) дисциплинарных взысканий;<br/>
                    •	данные о регистрации брака;<br/>
                    •	сведения о воинском учете;<br/>
                    •	сведения об инвалидности и иные сведения о состоянии здоровья, добровольно сообщаемые работником Оператору;<br/>
                    •	сведения об удержании алиментов;<br/>
                    •	сведения о доходе с предыдущего места работы;<br/>
                    •	иные персональные данные, предоставляемые работниками в соответствии с требованиями трудового законодательства.
                </p>
                <p>
                    4.2.3. Члены семьи работников Оператора:<br/>
                    •	фамилия, имя, отчество;<br/>
                    •	степень родства;<br/>
                    •	год рождения;<br/>
                    •	иные персональные данные, предоставляемые работниками в соответствии с требованиями трудового законодательства.
                </p>
                <p>4.2.4. Контрагенты Оператора (физические лица):</p>
                <p>
                    •	фамилия, имя, отчество;<br/>
                    •	дата и место рождения;<br/>
                    •	паспортные данные;<br/>
                    •	адрес регистрации по месту жительства;<br/>
                    •	контактные данные;<br/>
                    •	сведения об образовании, роде деятельности, профессиональном опыте;<br/>
                    •	индивидуальный номер налогоплательщика;<br/>
                    •	банковские реквизиты;<br/>
                    •	иные персональные данные, предоставляемые контрагентами (физическими лицами), необходимые для заключения и исполнения договоров.
                </p>

                <p>
                    4.2.5. Представители (работники) контрагентов Оператора (юридических лиц):<br/>
                    •	фамилия, имя, отчество;<br/>
                    •	паспортные данные;<br/>
                    •	контактные данные;<br/>
                    •	замещаемая должность;<br/>
                    •	иные персональные данные, предоставляемые представителями (работниками) клиентов и контрагентов, необходимые для заключения и исполнения договоров.
                </p>
                <p>
                    4.2.6. Пользователи сайта Оператора:<br/>
                    •	фамилия, имя, отчество;<br/>
                    •	контактные данные;<br/>
                    •	иные персональные данные, предоставляемые пользователями сайте Оператора в пределах, установленных предоставленным участником согласием на обработку и (или) распространение персональных данных, а также регистрационными формами, заполняемыми при регистрации на сайте Оператора.
                </p>
                <p>4.2.11. Иные лица – персональные данные, предоставляемые субъектами таких персональных данных (физическими лицами) в соответствии с содержанием предоставленного такими субъектами персональных данных согласия на обработку (или) распространение) персональных данных. </p>
                <p>4.3. Обработка Оператором биометрических персональных данных (сведений, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность) осуществляется в соответствии с законодательством Российской Федерации.</p>
                <p>4.4. Оператором не осуществляется обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, за исключением случаев, предусмотренных законодательством РФ.</p>
                <p>4.5. Субъекты персональных данных, чьи персональные данные передаются Оператору для обработки и (или) распространения, вправе при предоставлении Оператору согласия на обработку и (или) распространение персональных данных ограничить перечень передаваемых для обработки и (или) распространения персональных данных или установить ограничения по способам такой обработки и (или) распространения в соответствии с законодательством Российской Федерации, действующим на момент предоставления согласия. </p>
                <p>4.6. Субъекты персональных данных, чьи персональные данные передаются Оператору для обработки и (или) распространения, вправе при предоставлении Оператору согласия на обработку и (или) распространение персональных данных предоставить согласие в отношение иных категорий персональных данных помимо указанных в пп.4.2.1. – 4.2.10 настоящей Политики, включая биометрические персональные данные и специальные персональные данные. </p>

                <h3>5. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>5.1. Обработка персональных данных осуществляется Оператором в соответствии с требованиями законодательства Российской Федерации.</p>
                <p>5.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных на обработку их персональных данных, а также без такового в случаях, предусмотренных законодательством Российской Федерации.</p>
                <p>5.3. Оператор осуществляет как автоматизированную, так и неавтоматизированную обработку персональных данных.</p>
                <p>5.4. К обработке персональных данных допускаются работники Оператора, которые в связи с осуществлением должностных обязанностей осуществляют обработку персональных данных, предоставленных Оператору.</p>
                <p>
                    5.5. Обработка персональных данных осуществляется путем:<br/>
                    •	получения персональных данных в устной и письменной форме непосредственно от субъектов персональных данных;<br/>
                    •	получения персональных данных из общедоступных источников;<br/>
                    •	внесения персональных данных в журналы, реестры и информационные системы Оператора;<br/>
                    •	использования персональных данных в целях информирования субъектов персональных данных о деятельности Оператора;<br/>
                    •	передачи персональных третьим лицам с правом обработки персональных данных такими третьими лицами с учетом целей обработки, устанавливаемых в соответствии с предоставленных субъектом персональных данных согласием, а также с учетом настоящей Политики;<br/>
                    •	использования иных способов обработки персональных данных.
                </p>
                
                <p>
                    5.6. Не допускается раскрытие третьим лицам и распространение персональных данных без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, настоящей Политикой или не отвечает целям обработки персональных данных, установленных в соответствии с согласием субъекта персональных данных на обработку персональных данных. <br/>
                    Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных.<br/>
                    Требования к содержанию согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения, утверждены Приказом Роскомнадзора от 24.02.2021 № 18.
                </p>
                <p>5.7. Передача персональных данных органам дознания и следствия, в Федеральную налоговую службу, Фонд пенсионного и социального страхования и другие уполномоченные органы исполнительной власти и организации осуществляется в соответствии с требованиями законодательства Российской Федерации.</p>
                <p>5.8. Помимо случаев, указанных в согласии субъекта персональных данных Оператор вправе раскрыть персональные данные в следующих случаях:</p>
                <p>5.8.1. в ответ на требования органов государственной власти;</p>
                <p>5.8.2. в соответствии с требованиями судебного процесса в связи с осуществлением правосудия;</p>
                <p>5.8.3. в случае необходимости защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных;</p>
                <p>5.8.4. в целях защиты прав Оператора или уменьшения возможного ущерба Оператору;</p>
                <p>5.8.5. после обезличивания персональных данных;</p>
                <p>5.8.6. если персональные данные передаются в целях заключения и/или исполнения договора, по которому субъект персональных данных является одной из сторон, выгодоприобретателем или поручителем;</p>
                <p>5.8.7.      в чрезвычайных ситуациях.</p>
                <p>
                    5.9. Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других несанкционированных действий, в том числе:<br/>
                    •	определяет угрозы безопасности персональных данных при их обработке;<br/>
                    •	принимает локальные нормативные акты и иные документы, регулирующие отношения в сфере обработки и защиты персональных данных;<br/>
                    •	назначает лиц, ответственных за обеспечение безопасности персональных данных в структурных подразделениях и информационных системах Оператора;<br/>
                    •	создает необходимые условия для работы с персональными данными;<br/>
                    •	организует учет документов, содержащих персональные данные;<br/>
                    •	организует работу с информационными системами, в которых обрабатываются персональные данные;<br/>
                    •	хранит персональные данные в условиях, при которых обеспечивается их сохранность и исключается неправомерный доступ к ним;<br/>
                    •	организует при необходимости обучение и информирование работников Оператора, осуществляющих обработку персональных данных.
                </p>
                <p>5.10. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором.</p>
                <p>5.11. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети Интернет, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в Законе о персональных данных.</p>

                <h3>6. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ НА ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h3>
                <p>6.1. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в ч. 7 ст. 14 Закона о персональных данных, предоставляются Оператором субъекту персональных данных или его представителю при обращении либо при получении запроса субъекта персональных данных или его представителя.</p>
                <p>В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.</p>
                <p>
                    Запрос должен содержать:<br/>
                    •	номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;<br/>
                    •	сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором;<br/>
                    •	подпись субъекта персональных данных или его представителя.
                </p>
                <p>Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.</p>
                <p>Если в обращении (запросе) субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.</p>
                <p>Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с ч. 8 ст. 14 Закона о персональных данных, в том числе если доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.</p>
                <p>6.2. В случае выявления неточных персональных данных при обращении субъекта персональных данных или его представителя либо по их запросу или по запросу Роскомнадзора Оператор осуществляет блокирование персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения указанного запроса на период проверки, если блокирование персональных данных не нарушает права и законные интересы субъекта персональных данных или третьих лиц.</p>
                <p>В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных субъектом персональных данных или его представителем либо Роскомнадзором, или иных необходимых документов уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает блокирование персональных данных.</p>
                <p>6.3. В случае выявления неправомерной обработки персональных данных при обращении (запросе) субъекта персональных данных или его представителя либо Роскомнадзора Оператор осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения запроса.</p>
                <p>
                    6.4. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку персональные данные подлежат уничтожению, если:<br/>
                    •	иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем, по которому является субъект персональных данных;<br/>
                    •	оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях, предусмотренных Законом о персональных данных или иными федеральными законами;<br/>
                    •	иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных.
                </p>
                
                <h3>7.	ИНФОРМАЦИЯ ДЛЯ ПОЛЬЗОВАТЕЛЕЙ В ОТНОШЕНИИ COOKIES</h3>
                <p>7.1.	Во время просмотра Сайта на устройство Пользователя загружается сама страница, а также Cookies. </p>
                <p>
                    7.2.	У Пользователей имеется техническая возможность изменять количество и типы сохраняемых Cookies следующим образом: <br/>
                    (1) автоматически принимать все Cookies, <br/>
                    (2) предупреждать каждый раз, когда сайт пытается записать свой Cookies на оборудование Пользователя, <br/>
                    (3) не принимать Cookies. 
                </p>
                <p>
                    В зависимости от выбора Пользователя некоторые персональные услуги не смогут быть предоставлены.
                </p>
                <p>
                    7.3.	Сайт защищает данные Пользователя, полученные посредством Cookies, которые автоматически передаются при посещении страниц:<br/>
                    - IP адрес;<br/>
                    - информация из Cookies;<br/>
                    - информация о браузере <br/>
                    - время доступа;<br/>
                    - реферер (адрес предыдущей страницы).
                </p>
                <p>7.4.	Отключение cookies может повлечь невозможность доступа к частям Cайта, требующим авторизации.</p>
                <p>7.5.	Сайт осуществляет сбор статистики об IP-адресах Пользователей. Данная информация используется с целью предотвращения, выявления и решения технических проблем.</p>
                <p>7.6. Любая иная персональная информация неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных настоящей Политикой.</p>
                <p>7.7.	Срок хранения Cookies может длиться с момента загрузки Cookies на устройство Пользователя до конца данной конкретной сессии работы с Сайтом («сеансовые» Cookies) или бессрочно («постоянные» Cookies). </p>
                <p>7.8.	Cookies могут быть использованы для целей размещения и предоставления персонализированной рекламы, адаптированной под Пользователя.</p>
                <p>7.9.	Cookies третьих лиц. </p>
                <p>Администратор не несет ответственности за Cookies третьих лиц, полученные Пользователем, в том числе в процессе получения доступа к Сайту. </p>
                <p>7.10.	Администратор может использовать аналитические веб-службы (например, Яндекс.Метрика, GoogleAnalytics) для целей выявления особенностей обращения Пользователей к Сайту, оценки релевантности, удобства использования и актуальности информационного наполнения Сайта и Продуктов Владельца Сайта. </p>
                <p>7.11.	Указанные в п. 7.10. аналитические веб-службы могут использовать веб-маяки – электронные изображения, которые размещают Cookies, подсчитывают число посещений и оценивают показатели использования и эффективность использования Сайта. Данные сведения используются Администратором для анализа, какая информация интересует Пользователей. Веб-маяки анонимны, не содержат и не собирают идентифицирующую Пользователей информацию. </p>
            </div>
        </section>
    )
}