import { Link } from 'react-router-dom'
import rev3 from '../../images/rev3.png'

export const ReviewsListItem = (props: any) => {
    return( 
        <div className={`col-md-${props.col}`}>
            <div className="reviews-wrapper">
                <div className="reviews__fio row">
                    <div className="reviews__fio-img">
                        <div style={{backgroundImage: `url('${rev3}')`}}></div>
                    </div>
                    <div className="reviews__fio-text">
                        <div className="grid">
                            <div>
                                {props.item.fio}<br/>
                                <span>
                                    <Link to={`/course/${props.item.course.pk}/`}>{props.item.course.title}</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reviews-text">
                    <p>{props.item.review}</p>
                </div>
            </div>
        </div>
    )
}