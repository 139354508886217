import { useContext, useEffect, useState } from "react"
import { CoursesListItem } from "../../CoursesListItem"
import { PurchList } from "../../../classes/purch"
import logoLoader from '../../../images/logoLoader.svg'
import { Pagination } from "../../Pagination"
import { GlobalContext } from "../../../context/GlobalContext"
import { Module } from "./Module"
import { Courses } from "./Courses"

export const MyCoursesList = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [courses, setCourses] = useState(new PurchList())
    const [typeCourser, setTypeCourses] = useState(0)

    const GetData = (page: number, type = -1) => {
        setLoad(true)
        if(type != -1)
            setTypeCourses(type)
        else
            type = typeCourser
        
        courses.getData(page, type, auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })

    }

    useEffect(()=>{
        GetData(1)
    },[])

    if(load)
        return(
            <div className="load-block">
                <img src={logoLoader} />
                <p>Загрузка</p>
            </div>
        )

        
    return (
        <>
            <div className="account-type">
                <button 
                    className={`${typeCourser == 0 ? "active":""}`}
                    onClick={()=>{
                        GetData(1, 0)
                    }}
                    style={{marginRight: 20}}
                >
                    Курсы
                </button>
                <button
                    className={`${typeCourser == 1 ? "active":""}`}
                    onClick={()=>{
                        GetData(1, 1)
                    }}
                >
                    Модули
                </button>
            </div>
            <div className="row">
                {
                    typeCourser == 1 ? (
                        courses.modules.length ? (
                            courses.modules.map((item, index) => {
                                return(
                                    <div className="col-12">
                                        <Module item={item} />
                                    </div>
                                )
                            })
                        ):""
                    ):(
                        courses.course.length ? (
                            courses.course.map((item, index) => {
                                return(
                                    <div className="col-12 col-md-6">
                                        <Courses item={item} />
                                    </div>
                                )
                            })
                        ):""

                    )
                }
            
            </div>

            <div className="table-list__pager">    
                <Pagination 
                    page={courses.page}
                    pageCount={courses.pageCount}
                    setLoadReview={setLoad} 
                    SwitchPage={GetData}                         
                />      
            </div>
        </>
    )
}