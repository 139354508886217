import logo from '../../images/logo.svg'
import lens from '../../images/lens.svg'
import vk from '../../images/vk.svg'
import tg from '../../images/tg.svg'
import book from '../../images/book.svg'
import like from '../../images/like.svg'
import cart from '../../images/cart.svg'
import user from '../../images/user.svg'
import { GlobalContext } from '../../context/GlobalContext'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '../Loader'

export const Header = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)

    const queryParams = new URLSearchParams(window.location.search)
    const [search, setSearch] = useState(`${queryParams.get('s') ? queryParams.get('s') : ""}`)



    useEffect(()=>{
        if(auth.user.userToken != ""){
            auth.user.LoadData().then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }else{
            setLoad(false)
        }
    },[])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <header>
                <div className="content">
                    <div className="row">
                        <div className="col-6 col-md-5 col-lg-3 order-1">
                            <div className="row">
                                <div className="col-md-6">
                                    <Link to="/" className="grid">
                                        <img className="logo" src={logo} />
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <div className="grid">
                                        <p className="header-contact">
                                            <span>
                                                <Link to="mailto:info@alsfund.ru">info@alsfund.ru</Link>
                                            </span><br/>
                                            <Link to="tel:+74959685626">+7 (495) 968-56-26</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-4 order-3 order-md-2">
                            <form className="header-search" action={'/courses/'}>
                                <button>
                                    <img src={lens} />
                                </button>
                                <input type="text" name="s" placeholder="Поиск" value={search} onChange={(e)=>{setSearch(e.target.value)}} />
                            </form>
                        </div>
                        <div className="col-6 col-md-4 col-lg-5 order-2">
                            <div className="grid">
                                <div className="header-btns">
                                    <div style={{marginRight: "5px"}} className="hide-mob">
                                        <Link to="https://vk.com/als.russia" target='_blank'>
                                            <img src={vk} />
                                        </Link>
                                    </div>
                                    <div className="hide-mob">
                                        <Link to="https://t.me/alsfund" target="_blank">
                                            <img src={tg} />
                                        </Link>
                                    </div>
                                    <div>
                                        <div className="grid">
                                            <Link to="/courses/" className="header-img-btn" style={{backgroundImage: `url('${book}')`}}>
                                                <span>Каталог</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid">
                                            <Link to="/favourites/" className="header-img-btn" style={{backgroundImage: `url('${like}')`}}>
                                                <span>Избранное</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid">
                                            <Link to="/order/" className="header-img-btn" style={{backgroundImage: `url('${cart}')`}}>
                                                <span>Корзина</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid">
                                            <Link to={`${auth.user.userToken != "" ? "/account/":"/login/"}`} className="header-img-btn" style={{backgroundImage: `url('${user}')`}}>
                                                <span>
                                                    {`${auth.user.userToken != "" ? "Аккаунт":"Войти"}`}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}