import React from 'react';
import './App.css';
import './css/bootstrap-grid.css';
import { useMyRoutes } from './routes';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './layouts/layout';
import { Header } from './component/Header';
import { Footer } from './component/Footer';
import { GlobalContext } from './context/GlobalContext';
import { User } from './classes';

function App() {
    const routes = useMyRoutes() 
    return (
        <>
            <GlobalContext.Provider value={{
                user: new User,
            }}> 
                <Header />
                <Routes>
                    <Route element={<Layout />}>{routes}</Route>
                </Routes> 
                <Footer />
            </GlobalContext.Provider>
        </>
    );
}
export default App;