import { Link } from "react-router-dom"

export const CorporatePage = () => {
    return(
        <section>
            <div 
                className="content"
                style={{
                    paddingTop: "80px",
                    paddingBottom: "80px"
                }}
            >
                <h1
                    style={{
                        textAlign: "center",
                        fontSize: 40,
                        fontWeight: 500,
                        paddingBottom: 30
                    }}
                >
                    Данный раздел находится в разработке, скоро тут появится вся информация
                </h1>
                <p style={{
                    textAlign: "center"
                }}>
                    <Link 
                        to={'/'}
                        style={{
                            borderRadius: "62px",
                            backgroundColor: "var(--orange)",
                            display: "inline-block",
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "17px",
                            color: "var(--light-fray)",
                            padding: "17px 45px"
                        }}
                    >
                        Главная страница
                    </Link>
                </p>
            </div>
        </section>
    )
}